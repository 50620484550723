<template>
  <div class="rs-tooltip">
    <span
      ref="trigger"
      aria-describedby="tooltip"
      class="rs-tooltip__trigger"
      @mouseenter="!isMobile && !isExplainer && show()"
      @mouseleave="!isMobile && !isExplainer && hide()"
      @click="(isMobile || isExplainer) && toggle()"
      v-click-outside="onClickOutside"
    >
      <slot name="label"></slot>
    </span>
    <div
      ref="wrap"
      :class="{ 'is-positioned': (isMobile || isExplainer) && isShowing }"
      class="rs-tooltip__wrap"
    >
      <div ref="tooltip" role="tooltip" class="rs-tooltip__content">
        <slot name="text"></slot>
        <div class="rs-tooltip__arrow" data-popper-arrow></div>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import { debounce as _debounce } from "lodash";
import vClickOutside from "v-click-outside";

export default {
  name: "RSTooltip",
  props: {
    isExplainer: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      isMobile: false,
      isShowing: false,
      popperInstance: null,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    trigger() {
      return this.$refs.trigger;
    },
    tooltip() {
      return this.$refs.tooltip;
    },
    wrap() {
      return this.$refs.wrap;
    },
  },
  mounted() {
    this.handleCheckWindow();
    const that = this;
    window.addEventListener("resize", function () {
      that.handleCheckWindow();
    });
    this.initPopper();
  },
  beforeDestroy() {
    this.handleDestroy();
  },
  methods: {
    initPopper() {
      this.popperInstance = createPopper(this.trigger, this.tooltip, {
        placement: "bottom-start",
        container: "body",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 10],
            },
          },
        ],
      });
    },
    show() {
      this.tooltip.setAttribute("data-show", "");
      this.isShowing = true;
      // this.$store.dispatch("setObscured", true);
      // We need to tell Popper to update the tooltip position
      // after we show the tooltip, otherwise it will be incorrect
      this.popperInstance.update();
    },

    hide() {
      this.tooltip.removeAttribute("data-show");
      // this.$store.dispatch("setObscured", false);
      this.isShowing = false;
    },
    toggle() {
      this.isShowing = this.tooltip.hasAttribute("data-show");
      if (!this.isShowing) {
        this.show();
      } else {
        this.hide();
      }
    },
    onClickOutside() {
      this.hide();
    },
    handleCheckWindow: _debounce(async function () {
      if (window.innerWidth < 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.hide();
      }
    }, 500),
    handleDestroy() {
      if (this.popperInstance !== null) {
        this.popperInstance.destroy();
        this.popperInstance = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rs-tooltip {
  $self: &;

  &__wrap {
    &.is-positioned {
      position: absolute;
      z-index: 999;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background: rgba($grey-light-color, 0.5);
      backdrop-filter: blur(5px);

      display: flex;
      align-items: flex-end;

      #{$self} {
        &__content {
          position: relative !important;
          transform: none !important;
          max-width: none;
          width: 100%;
          left: 0;
          padding: 1rem;
          border-radius: 0;
          box-shadow: none;
        }

        &__arrow {
          display: none;
        }
      }
    }
  }

  &__content {
    background: $secondary-color;
    color: white;
    padding: 5px 10px;
    font-size: 0.825rem;
    border-radius: $border-radius;
    max-width: 300px;
    box-shadow: 0 0 15px rgba(black, 0.2);
    display: none;

    &[data-show] {
      display: block;
    }

    &[data-popper-placement^="top"] > .rs-tooltip__arrow {
      bottom: -4px;
    }

    &[data-popper-placement^="bottom"] > .rs-tooltip__arrow {
      top: -4px;
    }

    &[data-popper-placement^="left"] > .rs-tooltip__arrow {
      right: -4px;
    }

    &[data-popper-placement^="right"] > .rs-tooltip__arrow {
      left: -4px;
    }
  }

  &__arrow {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
    visibility: hidden;
    &:before {
      position: absolute;
      width: 8px;
      height: 8px;
      background: inherit;
      visibility: visible;
      content: "";
      transform: rotate(45deg);
    }
  }
}
</style>
