import Vue from "vue";

export default Vue.mixin({
  // WHen dynamic keep-alive component is shown, either when mounted or revisited
  // Focus the input immediately
  // data: () => ({
  //   hasActiveVar: false,
  // }),
  activated() {
    this.$focusInput("input");
  },
  methods: {
    // For items that are selected like answer buttons or boxes
    // that need to be focused when one is already selected;
    // set the "input" ref on the already selected item to give
    // it focus again.
    $currentRef(activeVar, index) {
      if (!activeVar && index === 0) {
        return "input";
      }
      if (activeVar === index) {
        return "input";
      } else {
        return "input-" + index;
      }
    },
    async $focusInput(refName) {
      // Make sure the ref is set if currentRef needs to be set on active item.
      await this.$currentRef;

      // When ref is in a v-for loop it will create an array, so we need to
      // use the first in array with [0] otherwise, access the input directly
      if (this.$refs[refName]) {
        if (this.$refs[refName] instanceof Array) {
          this.$refs[refName][0].focus();
        } else if (this.$refs[refName].$el) {
          this.$refs[refName].$el.focus();
        } else {
          this.$refs[refName].focus();
        }
      }
    },
  },
});
