<template>
  <div class="spinner" :style="{ width: size + 'px', height: size + 'px' }">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="22 22 44 44"
      style="transform: rotate(0deg)"
    >
      <circle
        fill="transparent"
        cx="44"
        cy="44"
        r="20"
        stroke-width="4"
        stroke-dasharray="125.664"
        stroke-dashoffset="1252px"
        class="v-progress-circular__overlay"
      ></circle>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 70,
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  position: relative;
  width: 70px;
  height: 70px;
}

svg {
  animation: progress-circular-rotate 1.4s linear infinite;
  transform-origin: center center;
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

circle {
  animation: progress-circular-dash 1.4s ease-in-out infinite;
  stroke-linecap: round;
  stroke-dasharray: 80, 200;
  stroke-dashoffset: 0px;

  stroke: $secondary-color;
  z-index: 2;
  transition: all 0.6s ease-in-out;
}

@keyframes progress-circular-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes progress-circular-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0px;
  }

  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -124px;
  }
}
</style>
