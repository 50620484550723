// import { updateLocalStorageObject } from "@/helpers";
import { API, graphqlOperation } from "aws-amplify";

export async function findOrCreateProspect(
  id,
  advisorId,
  advisorVanityName,
  source
) {
  if (!id) {
    // NO PROSPECT ID IN LOCAL STORAGE
    const prospect = await createDbProspect(
      advisorId,
      advisorVanityName,
      source
    );

    if (prospect) {
      return prospect;
    }
  } else {
    try {
      // YES PROSPECT ID IN LOCAL STORAGE
      // Check if they are in DB
      const query = `
      query GetProspect {
        getProspect(id: "${id}") {
          id
          email
          firstName
          lastName
          phone
          isAnonymous
          scoreSurveyAnswers
          scoreSurveyResults
          scoreSurveyComplete
          lastScoreStep,
          advisor {
            id
            email
          }
          advisorVanityName
        }
      }`;

      const prospect = await API.graphql(graphqlOperation(query));

      if (!prospect.data.getProspect) {
        // DO MUTATION TO SET USER ANON
        localStorage.removeItem("rs-pid");
        localStorage.removeItem("rs-answers");
        localStorage.removeItem("rs-pscore");

        const createdProspect = await createDbProspect(
          advisorId,
          advisorVanityName,
          source
        );

        if (createdProspect) {
          return createdProspect;
        }
      } else {
        // If prospect doesn't have an advisor set, but one is avail in prop, add it
        if (!prospect.data.getProspect.advisorId && advisorId) {
          await updateDbProspect({
            id: prospect.data.getProspect.id,
            advisorId: advisorId,
            advisorVanityName: advisorVanityName,
          });
        }
        return prospect.data.getProspect;
      }
    } catch (error) {
      console.log("Survey Error in findOrCreateProspect", error);
    }
  }
}

/**
 *
 * Update Prospect with progress data
 * @param {id, source }
 *
 * @returns {id, isAnonymous, email, firstName, lastName, phone,advisorId, advisor }
 *
 */
export async function createDbProspect(advisorId, advisorVanityName, source) {
  // console.log(
  //   "in createDbProspect....advisorId, vanity:",
  //   advisorId,
  //   advisorVanityName
  // );
  try {
    const createData = {};
    createData.isAnonymous = true;

    if (advisorId) {
      createData.advisorId = advisorId;
    }

    if (advisorVanityName) {
      createData.advisorVanityName = advisorVanityName;
    }

    if (source) {
      createData.scoreSurveySource = source;
    }

    const query = `
      mutation CreateProspect($isAnonymous: Boolean, $advisorId: ID, $advisorVanityName: String, $scoreSurveySource: String) {
        createProspect(input: {
          isAnonymous: $isAnonymous,
          advisorId: $advisorId,
          advisorVanityName: $advisorVanityName
          scoreSurveySource: $scoreSurveySource,
          type: "Prospect"
        }) {
          id,
          isAnonymous
          email
          firstName
          lastName
          phone
          advisorId
          advisorVanityName
          advisor {
            id
            email
          }
        }              
      }`;

    const variables = JSON.stringify(createData);

    const result = await API.graphql(graphqlOperation(query, variables));

    if (
      result.data &&
      result.data.createProspect &&
      result.data.createProspect.id
    ) {
      // localStorage.setItem("rs-pid", result.data.createProspect.id);

      return result.data.createProspect;
      // updateLocalStorageObject("rs-pid", null, result.data.createProspect.id);
    }
  } catch (error) {
    console.log("Survey ERROR Creating P in createDBProspect", error);
  }
}

/**
 *
 * Update Prospect with progress data
 * @param {data, isReset }
 *
 * @returns {id, ...}
 *
 */
export async function updateDbProspect(data, isReset) {
  try {
    const updateData = data;

    if (!updateData || !updateData.id) {
      return;
    }

    const query = `
      mutation UpdateProspect($id: ID!, $scoreSurveyComplete: Boolean, $scoreContactComplete: Boolean, $scoreSurveyResults: AWSJSON, $scoreSurveyAnswers: AWSJSON, $subscribed: Boolean, $firstName: String, $lastName: String, $phone: AWSPhone, $email: AWSEmail, $advisorId: ID, $advisorVanityName: String, $scoreAgreeTerms: Boolean, $sendReport: String) {
        updateProspect(input: {
          id: $id, 
          scoreSurveyComplete: $scoreSurveyComplete,
          scoreContactComplete: $scoreContactComplete,
          scoreSurveyResults: $scoreSurveyResults,
          scoreSurveyAnswers: $scoreSurveyAnswers
          subscribed: $subscribed,
          firstName: $firstName,
          lastName: $lastName,
          phone: $phone,
          email: $email,
          advisorId: $advisorId,
          advisorVanityName: $advisorVanityName,
          scoreAgreeTerms: $scoreAgreeTerms,
          sendReport: $sendReport
        }) {
          id,
          scoreSurveyComplete,
          scoreContactComplete,
          scoreSurveyResults,
          scoreSurveyAnswers,
          subscribed
          advisor {
            id
            email
          }
          advisorVanityName
        }
      }`;

    const variables = JSON.stringify(updateData);

    const prospect = await API.graphql(graphqlOperation(query, variables));

    if (prospect.data && prospect.data.updateProspect && !isReset) {
      localStorage.setItem(
        "rs-answers",
        prospect.data.updateProspect.scoreSurveyAnswers
      );
    }

    return prospect.data.updateProspect;
  } catch (error) {
    console.log("Survey Update Prospect Mutation Error: ", error);
  }
}

export async function getAdvisorByVanityName(vanityName) {
  if (!vanityName) {
    // NO VANITY NAME
    throw Error;
  } else {
    try {
      const query = `
      query GetAdvisorByVanityName {
        advisorByVanityName(vanityName: "${vanityName}") {
          items {
            id,
            scoreReportTemplateId,
            collectContactPostSurvey
          }
        }
      }`;

      const advisor = await API.graphql(graphqlOperation(query));
      console.log(
        "Response from get advisor by vanity",
        advisor.data.advisorByVanityName.items[0].id
      );

      if (advisor.data.advisorByVanityName.items.length > 0) {
        return advisor.data.advisorByVanityName.items[0];
      } else {
        throw Error;
      }
    } catch (error) {
      console.log("Survey Error in getAdvisorByVanityName", error);
    }
  }
}

export async function getAdvisorById(id) {
  if (!id) {
    // NO ADVISOR ID
    throw Error;
  } else {
    try {
      const query = `
      query GetAdvisorById {
        getAdvisor(id: "${id}") {
          id,
          scoreReportTemplateId,
          collectContactPostSurvey
        }
      }`;

      const advisor = await API.graphql(graphqlOperation(query));

      if (advisor) {
        return advisor.data.getAdvisor;
      } else {
        throw Error;
      }
    } catch (error) {
      console.log("Survey Error in getAdvisorById", error);
    }
  }
}
