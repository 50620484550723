import Vue from "vue";
import Vuex from "vuex";
import questionsConfig from "@/questions";
import { updateDbProspect } from "@/services/gqlApi";
// import { updateLocalStorageObject } from "@/helpers";
import config from "@/config";
import { v4 as uuidV4 } from "uuid";

Vue.use(Vuex);

const getDefaultState = () => {
  const questionSet = questionsConfig();

  // Set each question with a unique id
  questionSet.forEach((q) => {
    q.id = uuidV4();
  });

  const defaultQuestion = questionSet[0];

  return {
    questions: questionSet,
    advisor: null,
    prospect: null,
    prospectAnswers: [],
    results: [],
    contactComplete: false,
    currentInfo: {
      currentQuestionIndex: 0,
      currentQuestion: defaultQuestion,
      stepDirection: "next",
    },
    envFromParent: "PROD",
    surveyComplete: false,
    isObscured: false,
    completeReportOpenTarget: null,
    reportReady: null,
  };
};

export default new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    SET_PROSPECT(state, payload) {
      state.prospect = payload;
    },
    SET_ADVISOR(state, payload) {
      state.advisor = payload;
    },
    SET_ENV_FROM_PARENT(state, payload) {
      state.envFromParent = payload;
    },
    SET_SURVEY_QUESTIONS(state, payload) {
      state.questions = payload;
      state.currentInfo.currentQuestion = payload[0];
    },
    // UPDATE_QUESTION(state, payload) {
    //   const question = state.questions.find((question) => {
    //     return question.id === payload.id;
    //   });
    //   if (question) {
    //     Object.assign(question, { ...payload });
    //   }
    // },
    UPDATE_ANSWERS(state, payload) {
      Object.assign(state.prospectAnswers, payload);
    },
    SET_CONTACT_COMPLETE(state, payload) {
      state.contactComplete = payload;
    },
    SET_SURVEY_COMPLETE(state, payload) {
      state.surveyComplete = payload;
    },
    SET_CURRENT_INFO(state, payload) {
      Object.assign(state.currentInfo, payload);
    },
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    SET_OBSCURED(state, payload) {
      state.isObscured = payload;
    },
    SET_COMPLETE_REPORT_OPEN_TARGET(state, payload) {
      state.completeReportOpenTarget = payload;
    },
    SET_REPORT_READY(state, payload) {
      state.reportReady = payload;
    },
  },
  actions: {
    setProspect({ commit }, payload) {
      return new Promise((resolve) => {
        commit("SET_PROSPECT", payload);
        resolve();
      });
    },
    setAdvisor({ commit }, payload) {
      commit("SET_ADVISOR", payload);
    },
    setEnvFromParent({ commit }, payload) {
      return new Promise((resolve) => {
        commit("SET_ENV_FROM_PARENT", payload);
        resolve();
      });
    },
    setSurveyQuestions({ commit }, payload) {
      return new Promise((resolve) => {
        commit("SET_SURVEY_QUESTIONS", payload);
        resolve();
      });
    },
    updateQuestion({ commit, state }, payload) {
      return new Promise((resolve) => {
        const questionData = Object.assign({}, payload.data);
        const direction = payload.direction;

        if (questionData.scoringParam.startsWith("prospect_")) {
          resolve("prospectData");
          return;
        }

        const answerData = {
          id: questionData.id,
          param: questionData.scoringParam,
          value: questionData.value,
          valid: questionData.valid,
          disabled: questionData.disabled,
        };
        const existingAnswerIndex = state.prospectAnswers.findIndex((a) => {
          return a.id === questionData.id;
        });

        let currentAnswers = [];
        currentAnswers = state.prospectAnswers;

        if (direction === "previous") {
          // If on first question, don't make any changes

          // if (state.prospectAnswers.length === 1) {
          //   return;
          // }

          // Remove last item and assign new state
          currentAnswers.splice(existingAnswerIndex, currentAnswers.length);
        } else {
          // If it's a next direction, and the answer already exists in the array,
          // update it, otherwise add it to the array
          if (existingAnswerIndex > -1) {
            Object.assign(currentAnswers[existingAnswerIndex], answerData);
          } else {
            // console.log("PUSH ANSWER", answerData);
            if (answerData.valid) {
              currentAnswers.push(answerData);
            }
          }
        }

        /**
         *
         * TODO ABSTRACT THIS OUT
         *
         * CHECK IF IT THERE ARE Q's TO TOGGLE BASED ON THE INCOMING ANSWER
         *
         * */
        // TOGGLE FinancesRetirementSavingsMonth
        if (questionData.scoringParam === "retirement_age") {
          const questionToHide = state.questions.find(
            (s) => s.componentName === "FinancesRetirementSavingsMonth"
          );

          if (direction === "previous") {
            questionToHide.value = "";
            questionToHide.disabled = false;
          } else {
            if (questionData.value === "I Am Already Retired") {
              questionToHide.disabled = true;
            } else {
              questionToHide.value = "";
              questionToHide.disabled = false;
            }
          }
        }

        // TOGGLE FinancesSocialSecurityIncome
        if (questionData.scoringParam === "social_security_start_age") {
          const incomeComponent = state.questions.find(
            (s) => s.componentName === "FinancesEstimatedIncome"
          );

          const ssIncomeComponent = state.questions.find(
            (s) => s.componentName === "FinancesSocialSecurityIncome"
          );

          if (direction === "previous") {
            // IS taking SS, show question
            incomeComponent.disabled = false;
            incomeComponent.value = "";

            ssIncomeComponent.disabled = false;
            ssIncomeComponent.value = "";
          } else {
            const currentYear = new Date().getFullYear();
            const yearBorn = currentAnswers.find(
              (a) => a.param === "year_born"
            );

            const currentAge = currentYear - yearBorn.value;
            const ssStartAge = questionData.value;

            if (currentAge && ssStartAge) {
              if (currentAge >= ssStartAge) {
                // IS taking SS, show question
                incomeComponent.disabled = true;
                incomeComponent.value = "";

                ssIncomeComponent.disabled = false;
              } else {
                // Not taking SS, hide question
                incomeComponent.disabled = false;

                ssIncomeComponent.disabled = true;
                ssIncomeComponent.value = "";
              }
            }
          }
        }
        // END CHECK IF IT THERE ARE Q's TO TOGGLE

        // Update Database
        const updateData = {
          id: state.prospect.id,
          scoreSurveyAnswers: JSON.stringify(state.prospectAnswers),
        };
        updateDbProspect(updateData)
          .then((response) => {
            commit("UPDATE_ANSWERS", currentAnswers);
            // commit("UPDATE_QUESTION", questionData);
            resolve(response);
          })
          .catch((response) => {
            console.log("Error: ", response);
          });
      });
    },
    async setContactComplete({ commit, dispatch, state }, payload) {
      commit("SET_CONTACT_COMPLETE", payload);
      console.log("IN setContactComplete", payload);
      try {
        const updateData = {
          id: state.prospect.id,
          scoreContactComplete: state.contactComplete,
        };

        const result = await updateDbProspect(updateData);
        if (result.scoreContactComplete) {
          dispatch("redirectToScoreReport", result.id);
        }
      } catch (error) {
        console.log("Error in setContactComplete Action: ", error);
      }
    },
    setSurveyComplete({ commit }, payload) {
      return new Promise((resolve) => {
        commit("SET_SURVEY_COMPLETE", payload);
        resolve();
      });
    },
    setCurrentInfo({ commit }, payload) {
      return new Promise((resolve) => {
        commit("SET_CURRENT_INFO", payload);
        resolve("currentInfoSet");
      });
    },
    resetState({ commit, payload }) {
      return new Promise((resolve) => {
        commit("RESET_STATE", payload);
        resolve();
      });
    },
    setObscured({ commit }, payload) {
      return new Promise((resolve) => {
        commit("SET_OBSCURED", payload);
        resolve();
      });
    },
    setCompleteReportOpenTarget({ commit }, payload) {
      return new Promise((resolve) => {
        commit("SET_COMPLETE_REPORT_OPEN_TARGET", payload);
        resolve();
      });
    },
    setReportReady({ commit }, payload) {
      return new Promise((resolve) => {
        commit("SET_REPORT_READY", payload);
        resolve();
      });
    },
    redirectToScoreReport({ state, dispatch }, payload) {
      const id = payload;
      const scoreReportOrigin = "SCORE_REPORT_ORIGIN_" + state.envFromParent;
      let scoreReportPath = "SCORE_REPORT_PATH";

      if (state.advisor && state.advisor.scoreReportTemplateId) {
        scoreReportPath = "SCORE_REPORT_PATH_ALT";
      }

      let origin = config[scoreReportOrigin] + config[scoreReportPath];
      console.log("redirectToScoreReport", id);
      // Send prospect to their report.
      if (state.completeReportOpenTarget === "_blank") {
        dispatch("setReportReady", true);
        window.open(origin + id, "_blank");
      } else {
        window.location.href = origin + id;
      }
    },
  },
  // modules: {},
});
