const questionsConfig = () => [
  {
    componentName: "DemographicsYearBorn",
    title: "What year were you born?",
    description: "",
    icon: "score_demographics.svg",
    disabled: false,
    valid: false,
    value: "",
    active: false,
    type: "select",
    helpText: {
      label: "Where is my birth year?",
      text: "The Social Security Risks Score is designed for individuals born between ",
    },
    scoringParam: "year_born",
    category: "demographics",
  },
  {
    componentName: "DemographicsAgeRetire",
    title: "At what age do you plan to retire?",
    description: "",
    icon: "score_calendar.svg",
    disabled: false,
    valid: false,
    value: "",
    active: false,
    type: "select",
    scoringParam: "retirement_age",
    category: "demographics",
  },
  {
    componentName: "DemographicsStartAge",
    title: [
      "What age will you start taking Social Security? ",
      "What age will you (or did you) start taking Social Security?",
      "What age did you start taking Social Security? ",
    ],
    description: "",
    icon: "score_government.svg",
    disabled: false,
    valid: false,
    value: "",
    active: false,
    type: "select",
    scoringParam: "social_security_start_age",
    category: "demographics",
  },
  {
    componentName: "DemographicsMarried",
    title: "Are you married?",
    description: "",
    icon: "score_married.svg",
    disabled: false,
    valid: false,
    value: null,
    active: false,
    type: "button",
    options: ["Yes", "No"],
    scoringParam: "married",
    category: "demographics",
  },
  {
    componentName: "DemographicsGender",
    title: "What is your gender?",
    description: "",
    icon: "score_gender.svg",
    disabled: false,
    valid: false,
    value: null,
    active: false,
    type: "button",
    options: [
      {
        value: "Female",
        icon: "woman-icon.svg",
      },
      {
        value: "Male",
        icon: "man-icon.svg",
      },
    ],
    scoringParam: "gender",
    category: "demographics",
  },
  {
    componentName: "DemographicsZipCode",
    title: "What is your zip code?",
    description: "",
    icon: "score_zipcode.svg",
    disabled: false,
    valid: false,
    value: null,
    active: false,
    type: "input",
    scoringParam: "zipcode",
    category: "demographics",
  },
  {
    componentName: "FinancesEstimatedIncome",
    title: "What is your current or most recent annual income?",
    description: "",
    icon: "score_income.svg",
    disabled: false,
    valid: false,
    value: "",
    active: false,
    type: "select",
    options: [
      "$0-$75,000",
      "$75,000-$100,000",
      "$100,001-$150,000",
      "$150,001-$400,000",
      "$401,000+",
    ],
    scoringParam: "income",
    category: "finances",
  },
  {
    componentName: "FinancesSocialSecurityIncome",
    title:
      "You have indicated you are currently taking Social Security. How much income did you receive from Social Security <u>last month</u>?",
    description: "",
    icon: "score_receiveSS.svg",
    disabled: false,
    valid: false,
    value: "",
    active: false,
    type: "select",
    options: [
      "$500-$1,500",
      "$1,500-$2,000",
      "$2,000-$2,500",
      "$2,500-$3,000",
      "$3,000+",
    ],
    scoringParam: "social_security_income",
    category: "finances",
  },
  {
    componentName: "FinancesRetirementSavings",
    title: "How much do you currently have saved for retirement?",
    description: "",
    icon: "score_savings.svg",
    disabled: false,
    valid: false,
    value: "",
    active: false,
    type: "select",
    options: [
      "$0",
      "$1,000-$100,000",
      "$100,000-$250,000",
      "$250,000-$500,000",
      "$500,000-$750,000",
      "$750,000-$1,000,000",
      "$1,000,000-$1,500,000",
      "$1,500,000-$2,000,000",
      "$2,000,000-$2,500,000",
      "$2,500,000-$3,000,000",
      "$3,000,000-$4,000,000",
      "$4,000,000-$5,000,000",
      "$5,000,000-$10,000,000",
      "$10,000,000+",
    ],
    scoringParam: "retirement_savings",
    category: "finances",
  },
  {
    componentName: "FinancesRetirementSavingsMonth",
    title: "How much do you save each month for retirement?",
    description: "",
    icon: "score_finances.svg",
    disabled: false,
    valid: false,
    value: "",
    active: false,
    type: "select",
    options: [
      "$0-$100",
      "$100-$250",
      "$250-$500",
      "$500-$750",
      "$750-$1,000",
      "$1,000-$1,250",
      "$1,250-$1,500",
      "$1,500-$1,750",
      "$1,750-$2,000",
      "$2,000+",
    ],
    scoringParam: "monthly_retirement_savings",
    category: "finances",
  },
  {
    componentName: "FinancesPension",
    title: [
      "How much pension income did you receive last month?",
      "How much pension income will you receive each month in retirement?",
    ],
    description: "",
    icon: "score_pension.svg",
    disabled: false,
    valid: false,
    value: "",
    active: false,
    type: "select",
    options: [
      "No Pension",
      "$100-$1,000",
      "$1,000-$2,000",
      "$2,000-$3,000",
      "$3,000-$5,000",
      "$5,000+",
    ],
    scoringParam: "pension_income",
    category: "finances",
  },
  {
    componentName: "FinancesEmergencyFund",
    title: "Do you have an emergency fund?",
    description: "",
    icon: "score_emergency.svg",
    disabled: false,
    valid: false,
    value: null,
    active: false,
    type: "button",
    options: ["Yes", "No"],
    scoringParam: "emergency_fund",
    category: "finances",
  },
  {
    componentName: "FinancesInvestmentStyle",
    title: "How would you describe your investment style?",
    description: "",
    icon: "score_investment.svg",
    disabled: false,
    valid: true,
    value: "Moderate",
    active: false,
    type: "slider",
    options: {
      Conservative: "Conservative",
      "Conservative-Moderate": "",
      Moderate: "Moderate",
      "Moderate-Aggressive": "",
      Aggressive: "Aggressive",
    },
    scoringParam: "investment_style",
    category: "finances",
  },
  {
    componentName: "FinancesStandardOfLiving",
    title: [
      "Which of these best describes how your spending habits have changed in retirement?",
      "Which of these best describes your planned spending habits in retirement?",
    ],
    description: "",
    icon: "score_spending.svg",
    disabled: false,
    valid: true,
    value: "Spend Same",
    active: false,
    type: "slider",
    options: {
      "Spend Less": "Spend Less",
      "Spend Less/Same": "",
      "Spend Same": "Spend Same",
      "Spend Same/More": "",
      "Spend More": "Spend More",
    },
    scoringParam: "spending_habits",
    category: "finances",
  },
  {
    componentName: "WellnessRateHealth",
    title: "How would you rate your overall health?",
    description: "",
    icon: "score_wellness.svg",
    disabled: false,
    valid: true,
    value: "Good",
    active: false,
    type: "slider",
    options: {
      Poor: "Poor",
      Good: "Good",
      Excellent: "Excellent",
    },
    scoringParam: "health",
    category: "wellness",
  },
  {
    componentName: "WellnessLiveUntil",
    title:
      "Based on your family longevity history and advances in medicine, how likely do you think it is that you'll live to age 100?",
    description: "",
    icon: "score_family.svg",
    disabled: false,
    valid: true,
    value: "Neither Likely nor Unlikely",
    active: false,
    type: "slider",
    options: {
      "Very Unlikely": "Very Unlikely",
      Unlikely: "",
      "Neither Likely nor Unlikely": "Neither Likely or Unlikely",
      Likely: "",
      "Very Likely": "Very Likely",
    },
    scoringParam: "longevity",
    category: "wellness",
  },
  {
    componentName: "WellnessLongTermCare",
    title:
      "Based on your medical history, how concerned are you that you may experience a long term care event?",
    description: "",
    icon: "score_longtermCare.svg",
    disabled: false,
    valid: true,
    value: "Neither Concerned or Unconcerned",
    active: false,
    type: "slider",
    options: {
      "Not Concerned": "Not Concerned",
      "Not Concerned/Neither": "",
      "Neither Concerned or Unconcerned": "Neither concerned nor unconcerned",
      "Neither/Concerned": "",
      Concerned: "Very Concerned",
    },
    scoringParam: "LTC",
    category: "wellness",
  },
  {
    componentName: "RetirementPlanHelp",
    title: "Who has helped you prepare for retirement?",
    description: "",
    icon: "score_retirmentPlan.svg",
    disabled: false,
    valid: false,
    value: null,
    active: false,
    type: "multi",
    options: [
      "I Don't Have a Plan",
      "Friend or Relative",
      "Myself",
      "Investment Professional",
      "Insurance Professional",
    ],
    scoringParam: "who_planned",
    category: "retirement_plan",
  },
  {
    componentName: "RetirementLeaveLegacy",
    title:
      "How important is leaving an inheritance or donation after you pass away?",
    description: "",
    icon: "score_donate.svg",
    disabled: false,
    valid: false,
    value: null,
    active: false,
    type: "button",
    options: [
      "Unimportant",
      "Neither Important or Unimportant",
      "Important",
      "Very Important",
    ],
    scoringParam: "inheritance",
    category: "retirement_plan",
  },
  {
    componentName: "ContactInfoEmail",
    title: "Where can you receive your risk score?",
    description:
      "<strong>Bonus:</strong> We'll send you a personalized risk report once you verify your contact info.",
    icon: "score_email.svg",
    disabled: false,
    valid: false,
    value: null,
    active: false,
    type: "input",
    skipFocus: true,
    scoringParam: "prospect_email_address",
    category: "get_your_score",
  },
  {
    componentName: "ContactInfoName",
    title: "Almost done! What's your name?",
    description: "",
    icon: "score_name.svg",
    disabled: false,
    valid: false,
    value: null,
    active: false,
    type: "input",
    skipFocus: true,
    scoringParam: "prospect_name",
    category: "get_your_score",
  },
  {
    componentName: "ContactInfoPhone",
    title: "Receive updates when your score changes.",
    description: "",
    icon: "score_phone.svg",
    disabled: false,
    valid: false,
    value: null,
    active: false,
    type: "input",
    skipFocus: true,
    scoringParam: "prospect_phone_number",
    category: "get_your_score",
  },
];

export default questionsConfig;
