<template>
  <div class="rs-app">
    <!-- set version in package.json -->
    <span class="rs-version">v 0.8.6 {{ envFromParent.substring(0, 1) }}</span>
    <div class="rs-loading" :class="{ 'is-loading': loading }">
      <h3>Preparing Your Risk Score Assessment</h3>
      <LoadingSpinnerCircular />
    </div>

    <template v-if="!loading">
      <!-- <a href="#" tabindex="-1" class="rs-about" @click.prevent="showModal">
        <img
          src="@/assets/question-icon.svg"
          alt="Question mark"
          aria-hidden="true"
        />
        About this Risk Score
      </a> -->

      <div class="rs-risk-main">
        <h1 v-if="windowWidth < 768">{{ mainTitle }}<sup>tm</sup></h1>
        <div class="rs-content">
          <div ref="toolTipBoundary" class="rs-content__center">
            <template>
              <h1 v-if="windowWidth > 768">{{ mainTitle }}<sup>tm</sup></h1>
              <div
                class="rs-content__survey"
                :class="isObscured ? 'rs-obscured' : ''"
              >
                <Survey
                  v-if="
                    enabledQuestions.length > 0 &&
                    currentInfo.currentQuestion &&
                    prospect &&
                    !resetting &&
                    !reportReady
                  "
                  :currentInfo="currentInfo"
                  :questions="enabledQuestions"
                  :totalSteps="totalSteps"
                  :envFromParent="envFromParent"
                  :prospect="prospect"
                  :skipContactSteps="skipContactSteps"
                  :prospectAnswers="prospectAnswers"
                  :paramMapping="paramMapping"
                  @resetSurvey="handleReset"
                />
                <div v-if="reportReady" class="rs-content__report-ready">
                  <h2>Assessment Complete!</h2>

                  <p>
                    <button
                      type="button"
                      @click="handleOpenReport()"
                      class="rs-button rs-button--submit"
                    >
                      View Report
                    </button>
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="rs-sidebar">
          <radial-progress-bar
            class="rs-progress"
            start-color="#178AF4"
            stop-color="#178AF4"
            inner-stroke-color="#f2f2f2"
            timing-func="cubic-bezier(0.445, 0.05, 0.55, 0.95)"
            :diameter="progressSize"
            :completed-steps="completedSteps"
            :total-steps="totalSteps + 1"
            :stroke-width="progressStrokeWidth"
            :inner-stroke-width="progressInnerStrokeWidth"
            :animate-speed="500"
            :is-clockwise="true"
          >
            <p>
              <span class="rs-progress__percent">{{ progressPercentage }}</span
              ><br /><span class="rs-progress__count"
                >{{ currentInfo.currentQuestionIndex + 1 }} /
                {{ totalSteps }}</span
              >
            </p>
          </radial-progress-bar>

          <div class="rs-categories-wrap">
            <CategoriesList
              v-if="currentInfo"
              :currentInfo="currentInfo"
              :enabledQuestions="enabledQuestions"
              :skipContactSteps="skipContactSteps"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Amplify } from "aws-amplify";
import config from "@/config";

import CategoriesList from "@/components/CategoriesList";
// import Explainer from "@/components/Explainer";
import LoadingSpinnerCircular from "@/components/LoadingSpinnerCircular";
// import Modal from "@/components/Modal";
import RadialProgressBar from "vue-radial-progress";
import Survey from "@/components/Survey";

// Need store in App.vue for webComponent, not in main.js
import store from "./store";
import { mapState } from "vuex";

import focusInputMixin from "@/mixins/focusInputMixin";

import {
  findOrCreateProspect,
  getAdvisorById,
  getAdvisorByVanityName,
  updateDbProspect,
} from "./services/gqlApi";
// import { updateLocalStorageObject } from "@/helpers";

export default {
  store,
  focusInputMixin,
  name: "RiskScoreSurvey",
  props: {
    externalReset: {
      type: Boolean,
    },
    vanity: {
      // must be all lowercase
      type: String,
      default: "",
    },
    id: {
      // must be all lowercase
      type: String,
      default: "",
    },
    advisorId: {
      // must be all lowercase
      type: String,
      default: "",
    },
    source: {
      // must be all lowercase
      type: String,
      default: "",
    },
    env: {
      // must be all lowercase
      type: String,
      default: "",
    },
    prospectId: {
      type: String,
      default: "",
    },
    prospectContactData: {
      type: Object,
    },
    reportOpenTarget: {
      type: String,
      default: "_blank",
    },
  },
  components: {
    CategoriesList,
    // Explainer,
    LoadingSpinnerCircular,
    RadialProgressBar,
    Survey,
  },
  data: () => ({
    advisor: null,
    loading: false,
    mainTitle: "Social Security Risk Score",
    saveLaterText: "Save progress and continue later",
    answers: null,
    isModalVisible: false,
    windowWidth: null,
    progressSize: 150,
    progressStrokeWidth: 15,
    progressInnerStrokeWidth: 10,
    resetting: false,
    paramMapping: {
      name: "prospect_name",
      email: "prospect_email_address",
      phone: "prospect_phone_number",
    },
    setupSkipCreateProspect: false,
  }),
  computed: {
    ...mapState([
      "currentInfo",
      "questions",
      "prospectAnswers",
      "envFromParent",
      "prospect",
      "isObscured",
      "contactComplete",
      "surveyComplete",
      "completeReportOpenTarget",
      "reportReady",
    ]),
    advisorVanityFromParent() {
      if (this.vanity) {
        return this.vanity;
      } else {
        return "";
      }
    },
    advisorIdFromParent() {
      // Advisor id can be passed in as id prop (deprecated) or newer "advisor-id" on survey web component tag
      if (this.id || this.advisorId) {
        const advisorIdFromParent = this.advisorId ? this.advisorId : this.id;
        return advisorIdFromParent;
      } else {
        return "";
      }
    },
    // Steps to be used in survey. Updated when questions changes,
    // filters out any disabled steps for dynamic step purposes.
    enabledQuestions() {
      const oldSteps = this.questions;
      const newSteps = [];

      // If prospect ID is passed in or collectContactPostSurvey, skip all contact steps
      if (this.skipContactSteps) {
        oldSteps.forEach((s) => {
          if (s.category === "get_your_score") {
            s.disabled = true;
          }
        });
      }

      if (oldSteps && oldSteps.length > 0) {
        oldSteps.forEach((s) => {
          if (!s.disabled) {
            newSteps.push(s);
          }
        });
      }

      return newSteps;
    },
    completedSteps() {
      const currentInfo = this.currentInfo;

      if (
        !currentInfo.currentQuestionIndex ||
        currentInfo.currentQuestionIndex === 0
      ) {
        return 1;
      } else if (currentInfo.currentQuestionIndex === this.totalSteps) {
        return this.totalSteps - 1;
      } else {
        return currentInfo.currentQuestionIndex + 1;
      }
    },
    totalSteps() {
      return this.enabledQuestions.length;
    },

    progressComplete() {
      return (
        (this.currentInfo.currentQuestionIndex + 1) / (this.totalSteps + 1)
      );
    },
    progressPercentage() {
      const rawPercent =
        (this.currentInfo.currentQuestionIndex / this.totalSteps) * 100;

      return Math.ceil(rawPercent / 5) * 5 + "%";
    },
    skipContactSteps() {
      // skip contact steps if a prospect-id is passed into survey
      // check prospect id is a uuid with regex
      const regex = new RegExp(
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
      );

      const isValidUuid = regex.test(this.prospectId);
      return (
        isValidUuid || (this.advisor && this.advisor.collectContactPostSurvey)
      );
    },
  },
  watch: {
    windowWidth() {
      if (this.windowWidth > 768) {
        this.progressStrokeWidth = 15;
        this.progressInnerStrokeWidth = 10;
        this.progressSize = 150;
      } else {
        this.progressStrokeWidth = 10;
        this.progressInnerStrokeWidth = 8;
        this.progressSize = 85;
      }
    },
    // externalReset: {
    //   handler(n, o) {
    //     console.log("SURVEY WATCH: ExternalReset: ", n, o);
    //     if (n === true) {
    //       this.handleReset(true, true);
    //     }
    //   },
    //   deep: true,
    //   // immediate: true,
    // },
  },
  created() {
    this.windowWidth = window.innerWidth;
  },
  mounted() {
    // Reset Initially as sometimes when the
    this.$store.dispatch("resetState").then(() => {
      localStorage.removeItem("rs-pid");
      localStorage.removeItem("rs-answers");
      localStorage.removeItem("rs-pscore");
      this.setEnvironment();
      this.init();
    });

    // AWS Auth check for testing
    // Auth.currentCredentials()
    //   .then((d) => {
    //     console.log("AWS AUTH data -> ", d);
    //   })
    //   .catch((e) => console.log("AWS AUTH error: ", e));

    // Font import  and load in parent head
    const fontImport = document.createElement("link");
    this.setAttributes(fontImport, [
      {
        name: "href",
        value:
          "https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap",
      },
      { name: "rel", value: "stylesheet" },
      { name: "type", value: "text/css" },
    ]);
    document.head.appendChild(fontImport);

    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  methods: {
    async init() {
      /**
       *
       *
       * SET PROD VS DEV WITH PASSED IN PROP SO CAN BUILD WITH CORRECT CONFIG DATA
       *
       */
      const IDENTITY_POOL = "IDENTITY_POOL_" + this.envFromParent;
      const GRAPHQL_ENDPOINT = "GRAPH_API_ENDPOINT_" + this.envFromParent;

      Amplify.configure({
        Auth: {
          // Amazon Cognito Identity Pool ID
          identityPoolId: config[IDENTITY_POOL],
          // REQUIRED - Amazon Cognito Region
          region: "us-east-1",
          ///Other configurations
        },
        API: {
          aws_appsync_graphqlEndpoint: config[GRAPHQL_ENDPOINT],
          aws_appsync_region: "us-east-1",
          aws_appsync_authenticationType: "AWS_IAM",
        },
      });

      this.setup();
    },
    setEnvironment() {
      let env = "PROD";
      if (
        this.env === "development" ||
        process.env.NODE_ENV === "development"
      ) {
        env = "DEV";
      } else if (this.env === "staging") {
        env = "STAGE";
      } else {
        env = "PROD";
      }

      this.$store.dispatch("setEnvFromParent", env);
      this.$store.dispatch(
        "setCompleteReportOpenTarget",
        this.reportOpenTarget
      );
    },
    async setup(existingProspect) {
      localStorage.removeItem("rs-answers");
      localStorage.removeItem("rs-pscore");
      this.loading = true;

      // Do we have advisor vanity name or advisor id passed in?
      // Set the id if we have id passed in. Otherwise, if we have
      // vanity name, use that to find the id. Otherwise, nothing.

      try {
        let advisor;
        if (this.advisorIdFromParent) {
          advisor = await getAdvisorById(this.advisorIdFromParent);
        } else if (this.advisorVanityFromParent) {
          advisor = await getAdvisorByVanityName(this.advisorVanityFromParent);
        }
        if (advisor) {
          this.advisor = advisor;
          this.advisor.reportOpenTarget = this.reportOpenTarget;
          this.$store.dispatch("setAdvisor", this.advisor);
        }
        if (existingProspect) {
          this.$store.dispatch("setProspect", existingProspect);
        } else if (this.prospectId) {
          // A prospect ID has been passed in, so there is an prospect record in db already. Go get it.
          localStorage.removeItem("rs-pid");
          const advisorId = this.advisor ? this.advisor.id : null;
          //  Prospect ID from Local Storage
          const prospect = await findOrCreateProspect(
            this.prospectId,
            advisorId,
            this.advisorVanityFromParent,
            this.source
          );

          if (prospect) {
            this.$store.dispatch("setProspect", prospect).then(() => {
              localStorage.setItem("rs-pid", prospect.id);
            });
          }
        } else {
          // New prospect, remove any existing in localStorage first
          localStorage.removeItem("rs-pid");
          const advisorId = this.advisor ? this.advisor.id : null;

          const prospect = await findOrCreateProspect(
            null,
            advisorId,
            this.advisorVanityFromParent,
            this.source
          );

          if (prospect) {
            let newProspect = prospect;

            const prospectContactData = this.$props.prospectContactData;
            const prospectContactDataParsed =
              this.tryParseJSON(prospectContactData);

            if (prospectContactDataParsed) {
              // Loops through keys in the prospectContactData Object.
              // If the value for the key is truthy and the key exists in paramMapping,
              // it finds the corresponding object in oldSteps and sets its disabled property to true.
              for (let key in prospectContactDataParsed) {
                if (prospectContactDataParsed[key] && this.paramMapping[key]) {
                  let obj = this.questions.find(
                    (obj) => obj.scoringParam === this.paramMapping[key]
                  );
                  if (obj) {
                    // Update the db with these values
                    // If we are here, there was prospect data passed in and no existing prospectID,
                    // eg from the advisor dashboard assessment presenter
                    // so for each item passed in, these will be skipped in the UI in enabledQuestions computed prop

                    // If name prop has at least one valid first or last name, disable the step

                    if (
                      key === "name" &&
                      this.objectHasValidValues(prospectContactDataParsed[key])
                    ) {
                      obj.disabled = true;
                    } else if (prospectContactDataParsed[key]) {
                      obj.disabled = true;
                    }
                  }
                }
              }

              // For any passed in prospect contact data, add it to the new prospect
              // Extract the name object from otherObj.
              let { name, ...other } = prospectContactDataParsed;

              // Flatten the name object and merge it with the rest of otherObj and prospect.
              newProspect = { ...newProspect, ...name, ...other };

              const updateData = { id: newProspect.id, ...name, ...other };
              // console.log("UPDATE DATA FOR PROSPECT", updateData);
              const updatedProspect = await updateDbProspect(updateData);

              console.log("p", updatedProspect);
            }

            this.$store.dispatch("setProspect", newProspect).then(() => {
              localStorage.setItem("rs-pid", newProspect.id);
            });
          }
        }

        setTimeout(() => {
          this.loading = false;
        }, 500);
      } catch (error) {
        console.log("ERROR GETTING ADVISOR", error);
      }
    },
    // Create font link in parent document for fonts to work from web component
    setAttributes(elem, attrs) {
      attrs.forEach((attr) => {
        elem.setAttribute(attr.name, attr.value);
      });
    },
    objectHasValidValues(obj) {
      for (let key in obj) {
        if (obj[key] !== null && obj[key] !== "") {
          return true;
        }
      }
      return false;
    },
    tryParseJSON(str) {
      try {
        let result = JSON.parse(str);

        if (
          result === null ||
          result === undefined ||
          (result.constructor === Object && Object.keys(result).length === 0) ||
          (Array.isArray(result) && result.length === 0)
        ) {
          return false;
        }
        return result;
      } catch (e) {
        return false;
      }
    },
    async handleReset() {
      this.resetting = true;

      try {
        // if (clearStorage) {
        //   localStorage.removeItem("rs-pid");
        //   localStorage.removeItem("rs-answers");
        //   console.log("in handleReset about to disptach");
        //   this.$store.dispatch("resetState").then(() => {
        //     // this.setEnvironment();
        //     // this.init();

        //     let event = new CustomEvent("update", {
        //       detail: { reset: true },
        //       bubbles: true,
        //       composed: true,
        //       cancelable: false,
        //     });
        //     document.dispatchEvent(event);
        //   });
        // } else {
        const updateDbData = {
          id: localStorage.getItem("rs-pid"),
          scoreSurveyComplete: false,
          scoreSurveyAnswers: JSON.stringify({}),
        };

        const updatedProspect = await updateDbProspect(updateDbData, true);

        this.$store.dispatch("resetState").then(() => {
          this.setEnvironment();
          this.setup(updatedProspect);
          this.resetting = false;
        });
      } catch (error) {
        console.log("Error updating prospect in reset", error);
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    handleOpenReport() {
      this.$store.dispatch("redirectToScoreReport", this.prospect.id);
    },
  },
};
</script>

<style lang="scss">
@import "./scss/styles.scss";

h1 {
  sup {
    font-size: 0.375em;
    margin-left: 0.5em;
    top: -1em;
    text-transform: uppercase;
  }
}

.rs-metaLink {
  display: inline-block;
  margin-bottom: 1rem;
  color: rgba($app-text-color, 0.5);
  font-size: 0.875rem;
  @include media(">tablet") {
    font-size: inherit;
  }
}

/* .rs-about {
  position: absolute;
  bottom: 3%;
  left: 3%;
  z-index: 1;
  color: $primary-color;

  img {
    height: 1rem;
    width: 1rem;
    vertical-align: middle;
    margin-right: 0.5rem;
    margin-bottom: 0.1875rem;
  }
} */

.rs-about-modal {
  h2 {
    margin: 0;
  }
}

.rs-version {
  position: absolute;
  left: 1%;
  bottom: 1%;
  z-index: 1;
  color: rgba($app-text-color, 0.2);
  font-size: 0.625rem;
  padding: 0.625rem;
  @include media(">tablet") {
    left: auto;
    right: 1%;
  }
}
</style>
