export default {
  SCORE_REPORT_PATH: "your-score-report/",
  SCORE_REPORT_PATH_ALT: "score-report/",

  // DEV
  SCORE_REPORT_ORIGIN_DEV: "https://dev.riskscore.us/",

  SCORE_API_BASE_DEV: "https://dev.api.riskscore.us/",

  GRAPH_API_ENDPOINT_DEV:
    "https://gfh3blrkebeu5elan7nuu4crfm.appsync-api.us-east-1.amazonaws.com/graphql",

  IDENTITY_POOL_DEV: "us-east-1:2b6c33e0-db48-481a-b63c-cdb0ea68ab94",

  // STAGING
  SCORE_REPORT_ORIGIN_STAGE: "https://staging.riskscore.us/",

  SCORE_API_BASE_STAGE: "https://staging.api.riskscore.us/",

  GRAPH_API_ENDPOINT_STAGE:
    "https://kna3rzj22japno3rkgufjcegp4.appsync-api.us-east-1.amazonaws.com/graphql",

  IDENTITY_POOL_STAGE: "us-east-1:5dee1d6a-6330-4b2e-99d5-06869f00ad65",

  // PROD
  SCORE_REPORT_ORIGIN_PROD: "https://riskscore.us/",

  SCORE_API_BASE_PROD: "https://api.riskscore.us/",

  GRAPH_API_ENDPOINT_PROD:
    "https://roh2dmikwbfhdaithbwhj2yynm.appsync-api.us-east-1.amazonaws.com/graphql",

  IDENTITY_POOL_PROD: "us-east-1:f0fda246-e389-4eee-a4b3-40db6375f93e",
};
