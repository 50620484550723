<template>
  <div class="rs-categories">
    <div
      class="rs-category"
      v-for="(cat, index) in categories"
      :key="cat.name"
      :class="{ 'is-active': activeCategory === index }"
    >
      <div class="rs-category__title">{{ cat.title }}</div>

      <div v-if="cat.name !== 'get_your_score'" class="rs-category__count">
        <template v-if="!cat.complete">
          {{ cat.completeCount }} / {{ cat.questions.length }}
        </template>

        <lottie-animation
          v-else
          class="rs-category__lottie"
          ref="anim"
          :animationData="require('@/assets/check-lottie.json')"
          :loop="false"
          :autoPlay="true"
          :speed="1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
import checkLottie from "@/assets/check-lottie.json";
import { mapState } from "vuex";

export default {
  name: "Categories",
  props: ["enabledQuestions", "skipContactSteps"],
  components: {
    LottieAnimation,
  },
  data: () => ({
    categoryCheck: JSON.stringify(checkLottie),
    categories: [],
    currentCategoryObj: null,
    previousCategory: null,
  }),
  computed: {
    ...mapState(["currentInfo"]),
    activeCategory() {
      var index = this.categories.findIndex(
        (c) => c.name == this.currentInfo.currentQuestion.category
      );

      return index;
    },
  },
  created() {
    this.handleCurrentInfo(this.currentInfo);
    this.unsubscribeSetCurrentInfo = this.$store.subscribe(
      (mutation, state) => {
        if (mutation.type === "SET_CURRENT_INFO") {
          this.handleCurrentInfo(state.currentInfo);
        }
      }
    );
    this.unsubscribeResetState = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "RESET_STATE") {
        this.handleCurrentInfo(state.currentInfo, true);
      }
    });
  },
  beforeDestroy() {
    this.unsubscribeSetCurrentInfo();
    this.unsubscribeResetState();
  },
  methods: {
    generateCategoriesList() {
      const cats = [];

      this.enabledQuestions.forEach((q, index) => {
        // Go through each item in questions array
        // if category doesn't yet exist in categories array,
        // add it and add count of category questions for each category
        let hasCategory = cats.some((c) => c["name"] === q.category);

        if (!hasCategory) {
          cats.push({
            name: q.category,
            title: q.category.replaceAll("_", " "),
            completeCount: 0,
            complete: false,
            questions: [],
          });
        }

        let catIndex = cats.findIndex((cat) => cat.name === q.category);
        const questionObj = {};
        questionObj.id = q.id;
        questionObj.category = q.category;
        questionObj.valid - q.valid;
        questionObj.questionIndex = index;

        // Add some question data as objects to category questions array
        cats[catIndex].questions.push(questionObj);
      });

      if (this.skipContactSteps) {
        cats.push({
          name: "get_your_score",
          title: "Get Your Score",
          completeCount: 0,
          complete: false,
          questions: [],
        });
      }

      return cats;
    },
    updateCategoryQuestions(reset) {
      // For each enabled question, go through and set the category's questions array
      this.enabledQuestions.forEach((q, index) => {
        // if questions has a disabled item, find the item its
        // category and remove it from category otherwise put it back
        const catObj = this.categories.find((c) => c["name"] === q.category);
        // const catObjCompleteCount = catObj.completeCount;

        if (catObj.questions.some((cq) => cq.id === q.id)) {
          // question is already in the category's questions array

          if (q.disabled) {
            if (catObj.questions.some((cq) => cq.id === q.id)) {
              // disabled q is in the categories list
              const qObj = catObj.questions.find((cq) => cq.id === q.id);
              const qIndex = catObj.questions.indexOf(qObj);
              if (qIndex > -1) {
                // remove question
                catObj.questions.splice(qIndex, 1); // 2nd parameter means remove one item only

                catObj.completeCount + 1;
              }
            }
          }
        } else if (!q.disabled) {
          const questionObj = {};
          questionObj.id = q.id;
          questionObj.category = q.category;
          questionObj.valid - q.valid;
          questionObj.questionIndex = index;
          catObj.questions.push(questionObj);
        }
      });

      if (reset) {
        this.categories.forEach((c) => {
          c.completeCount = 0;
          c.complete = false;
        });
      }
    },
    async handleCurrentInfo(currentInfo, reset) {
      const currentQuestion = currentInfo.currentQuestion;
      const stepDirection = currentInfo.stepDirection;

      // Have to wait for categories before proceeding
      if (!this.categories || !this.categories.length) {
        this.categories = await this.generateCategoriesList();
      } else {
        this.updateCategoryQuestions(reset);
      }

      const cats = this.categories;

      // Get index of category in categories that matches the current question
      const catIndex = cats.findIndex(
        (cat) => cat.name === currentQuestion.category
      );

      const currentCatObj = cats[catIndex];

      if (catIndex < 0 || !currentCatObj) {
        return;
      }

      if (!this.previousCategory) {
        this.previousCategory = currentCatObj;
      }

      if (currentCatObj.questions.some((s) => s.id === currentQuestion.id)) {
        const questionIdx = currentCatObj.questions.findIndex(
          (q) => q.id === currentQuestion.id
        );

        if (stepDirection === "next") {
          if (currentCatObj.completeCount === 0) {
            currentCatObj.completeCount = 1;
          } else {
            // currentCatObj.completeCount++;
            currentCatObj.completeCount = questionIdx + 1;
          }

          if (
            this.previousCategory.name !== currentCatObj.name &&
            this.previousCategory.completeCount ===
              this.previousCategory.questions.length
          ) {
            this.previousCategory.complete = true;
          }
        } else if (stepDirection === "previous") {
          if (this.previousCategory.name !== currentCatObj.name) {
            currentCatObj.complete = false;
            this.previousCategory.completeCount = 0;
          } else {
            currentCatObj.completeCount--;
          }
        }
      }

      this.previousCategory = currentCatObj;
    },
  },
};
</script>

<style scoped lang="scss">
.rs-categories {
  /* padding-left: 8%; */

  @include media(">tablet") {
    margin-top: 10%;
  }
}

.rs-category {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.25rem;
  transition: all 0.15s linear;
  overflow: hidden;
  color: $grey-medium-color;
  font-size: 0.75rem;
  @include media(">tablet") {
    padding: 5% 5% 5% 10%;
    font-size: 1rem;
  }

  &:before {
    @include media(">tablet") {
      content: "";
      position: absolute;
      top: 50%;
      right: auto;
      left: 0;
      transform: translateY(-50%);
      height: calc(100% + 2px);
      width: 0;
      background-color: $secondary-color;
      transition: all 0.15s linear;
      transition-delay: 0.2s;
    }
  }

  &.is-active {
    font-weight: 700;

    color: $primary-color;
    @include media(">tablet") {
      padding-right: 5%;
      padding-left: 15%;
    }

    &:before {
      width: 8px;
    }
  }

  &__title {
    text-transform: capitalize;
  }

  &__count {
    font-size: 0.75rem;
    color: $grey-medium-color;
    white-space: nowrap;
    margin-left: 0.5rem;
    @include media(">tablet") {
      font-size: 0.875rem;
    }
  }

  &__lottie {
    width: 2rem;
    margin-right: -0.25rem;
  }
}
</style>
