<template>
  <div>
    <template v-if="loading"
      ><div
        class="rs-loading rs-loading--translucent"
        :class="{ 'is-loading': loading }"
      >
        <h3>{{ loadingMessage }}</h3>
        <LoadingSpinnerCircular /></div
    ></template>
    <template>
      <div
        ref="stepSurvey"
        class="rs-stepper"
        :class="{ 'rs-obscured': loading }"
      >
        <!-- <keep-alive> -->
        <component
          :is="stepComponent"
          :questionData="questionData"
          :config="config"
          :envFromParent="envFromParent"
          :key="questionData.id"
          @showModal="showModal = true"
        />
        <!-- </keep-alive> -->
      </div>

      <div class="rs-stepper__buttons">
        <button
          v-if="
            questionIndex !== 0 &&
            currentInfo.currentQuestion.category !== 'get_your_score'
          "
          @click="previous"
          :disabled="questionIndex === 0"
          :aria-disabled="questionIndex === 0"
          class="rs-button rs-button--outline"
        >
          Previous
        </button>

        <button
          ref="nextButton"
          type="button"
          @click="handleNextFunctionType"
          class="rs-button"
          :class="isLastStep ? 'rs-button--submit' : 'rs-button--primary'"
          :disabled="!questionData.valid || isWaiting"
          :focus="questionData.valid"
        >
          {{ nextStepText }}
        </button>
      </div>
      <a
        v-if="currentInfo.currentQuestion.category !== 'get_your_score'"
        @click.prevent="$emit('resetSurvey')"
        href="#"
        class="rs-metaLink"
        >Start Over</a
      >

      <Explainer
        v-else
        :scoringParam="currentInfo.currentQuestion.scoringParam"
      />

      <Modal v-if="showModal" @close="closeModal" class="rs-modal"
        ><template slot="header"> <h2>Terms of Service</h2></template>
        <template slot="body"><TermsConditions /></template
      ></Modal>
    </template>
  </div>
</template>

<script>
import config from "@/config";
import eventBus from "@/eventBus";
import Explainer from "@/components/Explainer";
import LoadingSpinnerCircular from "@/components/LoadingSpinnerCircular";
import Modal from "@/components/Modal";
import TermsConditions from "@/components/TermsConditions.vue";
import { updateDbProspect } from "@/services/gqlApi";
import { fetchScore } from "@/services/restApi";
import { mapState } from "vuex";

export default {
  props: [
    "questions",
    "totalSteps",
    "currentInfo",
    "envFromParent",
    "prospect",
    "prospectAnswers",
    "skipContactSteps",
    "paramMapping",
  ],
  components: {
    Explainer,
    LoadingSpinnerCircular,
    Modal,
    TermsConditions,
  },
  data() {
    return {
      config: config,
      showModal: false,
      loading: false,
      isWaiting: false,
    };
  },
  computed: {
    ...mapState(["surveyComplete"]),
    questionIndex() {
      return this.currentInfo.currentQuestionIndex;
    },
    questionData() {
      return this.questions[this.questionIndex];
    },
    previousQuestionData() {
      return this.questions[this.questionIndex - 1];
    },
    stepComponent() {
      this.questionIndex;
      return () =>
        import(
          `@/components/questions/${
            this.questions[this.questionIndex].componentName
          }.vue`
        );
    },
    nextStepText() {
      let stepText = "Next";

      if (this.questionIndex + 1 === this.totalSteps) {
        stepText = "Get Score";
      }

      return stepText;
    },
    isLastStep() {
      let isLast = false;

      if (this.questionIndex + 1 === this.totalSteps) {
        isLast = true;
      }

      return isLast;
    },
    loadingMessage() {
      return this.isLastStep
        ? "Generating your report..."
        : "Loading next question...";
    },
    isLastNonContactStep() {
      let isLast;
      const nextQuestionScoringParam = this.questions[this.questionIndex + 1]
        ? this.questions[this.questionIndex + 1].scoringParam
        : null;

      if (
        // Last question before contact questions
        // Depending on what is passed in for prospect, check each contact step param
        (!this.isLastStep &&
          Object.values(this.paramMapping).includes(
            nextQuestionScoringParam
          )) ||
        (this.skipContactSteps && this.isLastStep) ||
        (!nextQuestionScoringParam && this.isLastStep)
      ) {
        isLast = true;
      }
      return isLast;
    },
  },
  watch: {
    questionData: {
      deep: true,
      handler(q) {
        if (q.valid && !q.skipFocus) {
          this.$focusInput("nextButton");
        }
      },
    },
  },
  created() {
    // EVENT BUS
    // Listen for event to go next without user having to click button
    // For use with non input answers (buttons/boxes);
    eventBus.$on("forceProceedNext", (data) => {
      if (data) {
        this.handleNextFunctionType();
      }
    });
  },
  methods: {
    async handleNextFunctionType() {
      if (!this.questionData) {
        return;
      }

      if (this.questionData.scoringParam === "prospect_email_address") {
        this.loading = true;

        try {
          await updateDbProspect({
            id: this.prospect.id,
            email: this.questionData.value.email,
            scoreAgreeTerms: this.questionData.value.terms,
          });
        } catch (error) {
          console.log("Error updating prospect_email_address in survey.");
        } finally {
          this.loading = false;
        }
      }

      if (this.questionData.scoringParam === "prospect_name") {
        this.loading = true;
        try {
          if (
            this.questionData.value &&
            this.questionData.value.firstName &&
            this.questionData.value.lastName
          ) {
            await updateDbProspect({
              id: this.prospect.id,
              firstName: this.questionData.value.firstName,
              lastName: this.questionData.value.lastName,
            });
          }
        } catch (error) {
          console.log("Error prospect_name in survey.");
        } finally {
          this.loading = false;
        }
      }

      if (this.questionData.scoringParam === "prospect_phone_number") {
        this.loading = true;
        try {
          if (this.questionData.value) {
            await updateDbProspect({
              id: this.prospect.id,
              phone: this.questionData.value,
            });
          }
        } catch (error) {
          console.log("Error prospect_phone_number in survey.");
        } finally {
          this.loading = false;
        }
      }

      if (this.isLastNonContactStep) {
        this.loading = true;
        const scoreApiBase = "SCORE_API_BASE_" + this.envFromParent;
        const answersToSend = {};

        // this.prospectAnswers.forEach((a) => {
        //   // if (a.param !== "prospect_email_address") {

        //   // }
        //   answersToSend[a.param] = a.value;
        // });

        await Promise.all(
          this.prospectAnswers.map((a) => {
            answersToSend[a.param] = a.value;
          })
        );

        try {
          const scoreResults = await fetchScore(
            scoreApiBase,
            answersToSend,
            this.prospect.id
          );
          this.$store.dispatch("setSurveyComplete", true).then(() => {
            localStorage.setItem("rs-pscore", JSON.stringify(scoreResults));
          });
        } catch (error) {
          console.log("Error setting score complete in survey.");
        } finally {
          this.loading = false;
        }
      }

      if (this.isLastStep) {
        return this.getScore();
      } else {
        return this.next();
      }
    },
    handleDirectionChange(direction, questionIndex) {
      if (this.$refs.stepSurvey && this.$refs.stepSurvey.classList) {
        // Add animation class when step is rendered
        this.$refs.stepSurvey.classList.add("animateStep");

        this.handleUpdateCurrentInfo(direction, questionIndex);
      }
    },
    handleUpdateCurrentInfo(direction, questionIndex) {
      const newCurrentInfo = {
        currentQuestion: this.questions[questionIndex],
        currentQuestionIndex: questionIndex,
        stepDirection: direction,
      };

      this.$store.dispatch("setCurrentInfo", newCurrentInfo).then(() => {
        this.isWaiting = false;
      });
    },
    next() {
      this.isWaiting = true;
      let questionIndex = this.questionIndex;
      if (questionIndex <= this.totalSteps) {
        questionIndex++;

        this.$store
          .dispatch("updateQuestion", {
            data: this.questionData,
            direction: "next",
          })
          .then(() => {
            this.handleDirectionChange("next", questionIndex);
          });
      } else {
        return;
      }
    },
    previous() {
      let questionIndex = this.questionIndex;

      if (questionIndex > 0) {
        questionIndex--;

        this.$store
          .dispatch("updateQuestion", {
            data: this.previousQuestionData,
            direction: "previous",
          })
          .then(() => {
            this.handleDirectionChange("previous", questionIndex);
          });
      } else {
        return;
      }
    },
    getScore() {
      this.loading = true;
      this.$store
        .dispatch("updateQuestion", {
          data: this.questionData,
          direction: null,
        })
        .then(() => {
          if (this.skipContactSteps && this.surveyComplete) {
            // Send to report
            console.log(
              "Score Survey is Complete. Prospect ID: ",
              this.prospect.id
            );
            this.$store.dispatch("redirectToScoreReport", this.prospect.id);
          }

          if (!this.skipContactSteps) {
            this.$store.dispatch("setContactComplete", true);
          }
        });
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss">
.rs-stepper {
  $self: &;
  text-align: center;
  min-height: 18rem; // prevent jump
  /* @include media(">desktop") {
    min-height: 20rem;
  } */

  &.animateStep {
    .rs-question {
      animation-name: fadeIn;
    }
  }

  &__icon {
    img {
      width: 100px;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8%;
    margin-bottom: 2rem;

    .rs-button {
      min-width: 7rem;

      &:first-child {
        margin-right: 1rem;
        @include media(">desktop") {
          margin-right: 3rem;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.rs-question {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  width: 100%;

  &__title {
    max-width: 80%;
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
  &__subtitle {
    font-size: 1em;
    color: $grey-medium-color;
    @include media(">tablet") {
      max-width: 60%;
      margin: 0 auto;
    }
  }

  &__icon {
    width: 4rem;
    @include media(">tablet") {
      width: 5rem;
    }
  }
}

// Safari bug - Using a wrapper around answers bc answers with boxes with css grid don't display correctly
// b/c Safari has a problem due to the height: 100% on the boxes.
.rs-answer-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rs-answer {
  padding: 2rem 0 2rem 0;
  width: 85%;
  @include media(">tablet") {
    width: 50%;
    padding: 3rem 0 2rem 0;
  }

  &__slider-val {
    margin-bottom: 1%;
    @include media(">tablet") {
      display: none;
    }
  }

  &.has-boxes {
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(1, 90%);
    grid-row-gap: 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    @include media(">phone") {
      grid-template-columns: repeat(2, 40%);
      grid-column-gap: 1rem;
    }
    @include media(">tablet") {
      grid-template-columns: repeat(2, 35%);
      grid-auto-rows: 1fr;
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
    }

    .rs-box {
      height: 100%;
      min-height: 70px;
      font-size: 1.125rem;
      &:nth-last-child(1):nth-child(odd) {
        @include media(">phone") {
          grid-column: auto / span 2;
        }
      }

      > p,
      img {
        width: 70px;
        margin-bottom: 0;
        @include media(">tablet") {
          width: 100px;
        }
      }

      &.has-image {
        font-size: 1rem;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.rs-progress-bar {
  position: relative;
  z-index: 0;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 2rem;

  .vue-simple-progress {
    /* border: 1px solid rgba($primary-color, 1); */
    border-radius: $border-radius;
    overflow: hidden;
  }

  .vue-simple-progress-bar {
    border-radius: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
