import config from "@/config";
import { updateDbProspect } from "@/services/gqlApi";

export async function fetchScore(scoreApiBase, answers, prospectId) {
  // Fetch score by submitting scoring params

  try {
    const response = await fetch(config[scoreApiBase] + "score_v3", {
      method: "POST",
      headers: {
        // Authorization: `Bearer ${process.env.NEEDTOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(answers),
    });

    if (response.ok) {
      const results = await response.json();
      if (results.success) {
        const updatedProspect = await updateProspect(results, prospectId);
        if (updatedProspect) {
          return results;
        }
      } else {
        throw new Error("Validation failed fetching score.");
      }
    } else {
      throw new Error("Something went wrong fetching score");
    }
  } catch (error) {
    console.log("Score fetch error: ", error);
  }
}

async function updateProspect(results, prospectId) {
  const scoreResults = JSON.stringify(results);
  const updateData = {
    id: prospectId,
    scoreSurveyResults: scoreResults,
    scoreSurveyComplete: true,
    sendReport: "1",
  };
  try {
    const results = await updateDbProspect(updateData);
    return results;
  } catch (error) {
    console.log("Error updating prospect after fetch", error);
  }
}
