<template>
  <transition name="modal-fade" appear mode="out-in">
    <div class="modal-backdrop">
      <div
        ref="modal"
        tabindex="0"
        @keyup.esc="$emit('close')"
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        v-click-outside="onClickOutside"
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header"> </slot>
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
            <img src="@/assets/close-icon.svg" alt="close" />
          </button>
        </header>

        <section class="modal-body" id="modalDescription">
          <slot name="body"> </slot>
        </section>

        <footer class="modal-footer">
          <slot name="footer"> </slot>
          <button
            type="button"
            class="rs-button rs-button--submit"
            @click="close"
            aria-label="Close"
          >
            {{ buttonText }}
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
  name: "Modal",
  props: {
    buttonText: {
      type: String,
      default: "Close",
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    this.$refs.modal.focus();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    onClickOutside(event) {
      console.log("MODAL EVENT", event);
      if (event.target.className !== "js-terms-modal") {
        this.$emit("close");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /* background-color: rgba(80, 80, 80, 0.7); */
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.modal {
  max-width: 50rem;
  height: 100%;
  max-height: 40rem;
  margin-top: 2rem;
  margin: 2rem 2%;
  background: #ffffff;
  box-shadow: 0 0 5rem rgba(black, 0.3);
  overflow-x: auto;
  display: flex;
  flex-direction: column;

  &:focus {
    outline: none;
  }
}

.modal-header,
.modal-footer {
  padding: 1rem;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid $grey-medium-color;
  color: $primary-color;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid $grey-medium-color;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  overflow-y: hidden;
  overflow-y: scroll;
  padding: 3%;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  padding: 0.75rem;
  cursor: pointer;
  background: transparent;

  img {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.rs-button {
  max-width: 15rem;
  margin: 0 auto;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
