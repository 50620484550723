<template>
  <RSTooltip :isExplainer="true">
    <template #label
      ><a href="#" @click.prevent="" class="rs-metaLink"
        >Why do we need this information?</a
      ></template
    >
    <template #text>
      <h4>Why do we need your {{ context }}?</h4>
      <div>{{ explainers[scoringParam] }}</div></template
    >
  </RSTooltip>
</template>

<script>
import RSTooltip from "@/components/RSTooltip.vue";

export default {
  props: {
    scoringParam: {
      type: String,
      default: "",
    },
  },
  components: {
    RSTooltip,
  },
  data() {
    return {
      explainers: {
        prospect_email_address:
          "Your email address is used to send you a link to your risk score to access anytime.",
        prospect_name:
          "This information allows us to personalize your experience.",
        prospect_phone_number:
          "Provide your mobile phone number to receive alerts and discuss impacts of Social Security changes.",
      },
    };
  },
  computed: {
    context() {
      let context;
      const newString = this.scoringParam.replaceAll("_", " ");
      context = this.removeFirstWord(newString);

      return context;
    },
  },
  methods: {
    removeFirstWord(str) {
      const indexOfSpace = str.indexOf(" ");

      if (indexOfSpace === -1) {
        return "";
      }

      return str.substring(indexOfSpace + 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
